const EN = {
   "Hello": "Hello",
   "About Us": "About Us",
   "Vision": "Vision",
   "Mission": "Mission",
   "Join Us": "Join Us",
   "Rewards": "Rewards",
   "Subscribe": "Subscribe",
   "Learn More": "Discover",
   "headerTitle1": "Let's Explore Xcubbe App Future For The Creation",
   "headerTitle2": "Of Digital Content And Short Videos Together. ",
   "headerTitle3": "Let's Explore Xcubbe App Future For The Creation Of Digital Content And Short Videos Together.",
   "headerSubTitle": "We are committed to create an open, transparent and fair digital platform, functions of Xcubbe App including content creation and short videos.",
   "AboutUsTitle": "is a company dedicated to promoting the development of digital creation and the short video industry.",
   "AboutUsDesc": "We aim to transform the short video industry by creating a decentralized ecosystem, and leveraging the power of blockchain technology to empower digital creators and viewers.",
   "Our Vision": "Our Vision",
   "VisionTitle": "To create an open, transparent and fair industry which included of digital content creation and short videos creation. ",
   "VisionDesc": "In order to implement our vision, Xcubbe App has been developed, which is a diversified decentralized APP, function of Xcubbe including marketplace, short video, social networking, chain games, to meet the needs of users in different scenes.",
   "Marketplace": "Marketplace",
   "Short Video": "Short Video",
   "Social": "Social",
   "Games": "Game",
   "Information": "News",
   "Our Mission": "Our Mission",
   "MissionTitle": "We aim to transform the short video industry by creating a decentralized ecosystem, and leveraging the power of blockchain technology to empower digital creators and viewers.",
   "MissionDesc": "We will indicate the content as NFT, providing global exposure. Creating a new era of finance led by video creation and consumption, solidifying independence and growth within the crypto community. Creating an innovative investment opportunities and ecosystems for everyone through our strong capabilities.",
   "Our Strength": "Our Advantages",
   "StrengthTitle1": "The great advantage that",
   "StrengthTitle2": "has is our blockchain solution",
   "StrengthDesc": "This enables our platform to provide a secure, transparent experience. Coupled with a large community of like-minded users, we create a thriving community that helps users unlock greater earning potential.",
   "Our Creator": "Unique Mechanism",
   "CreatorTitle": "We have a unique equity distribution mechanism.",
   "CreatorDesc": "Through Xcubbe's unique mechanism, we reward the most active members of the community, achieving a fairer distribution of wealth and rewards.",
   "Our Belief": "We Believe",
   "BeliefTitle": "The decentralization and transparency of blockchain technology can provide better protection and support for digital creators and audiences, and can also promote the development of the entire short video industry.",
   "BeliefDesc": "Let's work together to establish a fairer and more open digital creative and short video industry!",
   "newsletterTitle": "Stay tuned for the latest updates!",
   "NewsletterDesc": "Wish to know more information and updates? Subscribe to our newsletter now!",
   "Enter Your Email": "Enter Your Email",
   "Subscribe Now": "Subscribe Now",
   "Join Us and": "By joining us, you can:",
   "card1Title": "Watch high quality of short video content in our platform",
   "card2Title": "Interact with digital creators from worldwide, and share your ideas and creativity",
   "card3Title": "Purchase your favourite items in our marketplace and support the digital",
   "card4Title": "Participate in our games and earn more rewards"
}

export default EN;