import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import mathCal from "../assets/img/aboutUs/mathCal.png";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleDown, FaAngleRight, FaAngleUp, FaMinus, FaPlus } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactHtmlParser from 'react-html-parser';

function Rules(props) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const faqAccordion = [
        // {
        //     title: translate(props.lang, 'How to start trading?'),
        //     EN_content: `
        //     <span className="accordionContent"><b>Step 1 - Register for a VCG account</b></span><br />
        //     <span className="accordionContent">If you haven't created a personal area, click this link to register for a VCG account:
        //         After creating a personal area, the system will automatically create a real trading account and a demo
        //         trading account (both MT5 accounts) for you. You can view these accounts by clicking on the account
        //         section in your personal area
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>Step 2 -  Verify personal information</b></span><br />
        //     <span className="accordionContent">After creating a personal area, it is necessary to fill out your economic profile and submit
        //         proof of identity and residence documents. This is a necessary measure to prevent fraud and protect
        //         VCG customers from identity theft.
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>Step 3 -  Make the first deposit</b></span><br />
        //     <span className="accordionContent">VCG partners with global payment providers to offer our customers easier and more
        //         convenient deposit and withdrawal services. To see available payment methods, please visit the help
        //         center.
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>Step 4 -  Trading Platform</b></span><br />
        //     <span className="accordionContent">Customers can use the most popular trading platform - CTrader - to trade on VCG. You can
        //         click here to download CTrader. (Include software download link)
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>Step 5 -  Choose a package to start trading</b></span><br />
        //     <span className="accordionContent">Now that you have successfully deposited your first funds into your account and set up your
        //         trading terminal, the only thing left to do is to choose an investment package. Don't wait any longer,
        //         let's get started.
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>Step 6 -  Registration completed</b></span><br />
        //     <span className="accordionContent">Registering for a VCG account is just the first step in your trading journey! There are many
        //         activities you can participate in after registration. To learn more about VCG's range of services, please
        //         click here. (Include Referral Info link)
        //     </span> <br /><br />`,

        //     ZH_content: `
        //     <span className="accordionContent"><b>第1步 - 注册一个 VCG 帐户</b></span><br />
        //     <span className="accordionContent">如果您还没有创建个人专区，请点击此链接注册一个VCG账号：
        //     创建个人专区后，系统会自动创建真实交易账户和模拟账户
        //     交易账户（均为 MT5 账户）。您可以通过单击帐户来查看这些帐户
        //     您个人区域中的部分
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>第2步 - 验证个人信息</b></span><br />
        //     <span className="accordionContent">创建个人专区后，需要填写您的经济概况并提交
        //     身份证明和居住证件。这是防止欺诈和保护的必要措施
        //     身份盗用的 VCG 客户。
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>第3步 - 进行第一笔存款</b></span><br />
        //     <span className="accordionContent">VCG 与全球支付提供商合作，为我们的客户提供更轻松、更多的服务
        //     便捷的存取款服务。要查看可用的付款方式，请访问帮助中心。
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>第4步 - 交易平台</b></span><br />
        //     <span className="accordionContent">客户可以使用最流行的交易平台——CTrader——在VCG上进行交易。你可以
        //     单击此处下载 CTrader。 （附上软件下载链接）
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>第5步 - 选择套餐开始交易</b></span><br />
        //     <span className="accordionContent">现在您已成功将第一笔资金存入您的帐户并设置您的
        //     交易终端，剩下要做的就是选择投资组合。不要再等了，让我们开始吧。
        //     </span> <br /><br />
        //     <span className="accordionContent"><b>第6步 - 注册完成</b></span><br />
        //     <span className="accordionContent">注册VCG账户只是您交易之旅的第一步！有许多
        //     报名后可参加的活动。要了解有关 VCG 服务范围的更多信息，请
        //     点击这里。 （包括推荐信息链接）
        //     </span> <br /><br />`
        // },
        {
            title: translate(props.lang, 'All championship participants must have an UPFX Ultimate account'),
            EN_content: translate(props.lang, "Participants must use an MT5 Ultimate account. The account can be with any of the UPFX entities with the “UPFX” trademark. It can be a new or existing account."),
        },
        {
            title: translate(props.lang, 'The minimum deposit and participation fee.'),
            EN_content: `
            <ul>
                <li>
                    The minimum deposit required for a new registered client to participate is $100 for and the participation fees for every participant is $100.
                </li>
                <li>
                The deposit can be made in any methods provided in the client portal.
                </li>
            </ul>
            `
        },
        {
            title: translate(props.lang, 'The championship period will be posted in the campaign session in the client portal '),
            EN_content: translate(props.lang, "Participants can register before or during the championship on any day up to the last day."),
        },
        {
            title: translate(props.lang, "Withdraw funds from participants' accounts at any time"),
            EN_content: `
            <ul>
                <li>
                    Participants can partially withdraw funds from their accounts at any time during the championship period.
                </li>
                <li>
                    Withdrawal profits during the championship period will not be counted in rate of return (It means that if you withdraw any profits that you make during a contest period, those profits will not be included in the calculation of your rate of return. In other words, your rate of return will only be based on the amount of money that you initially invested, and any profits that you leave in your account during the contest period. If you withdraw any profits, those profits will not be factored into the calculation of your rate of return.)
                </li>
                <li>
                    Participants can make full withdrawals only after thirty (30) days counting from the date of account opening and have a minimum of thirty (30) round-turn trades.
                </li>
            </ul>`,
        },
        {
            title: translate(props.lang, 'The winner of the championship will be the participant with the largest percentage gain during the championship period'),
            EN_content: `
                <span>Calculated using the Adjusted Rate on Investment (AROI) </span><br/><br/>
            `
        },
        {
            title: translate(props.lang, 'Participants can use any trading strategies permitted by their accounts and can trade any instruments available in their chosen jurisdiction.'),
            EN_content: translate(props.lang, "However, a minimum of thirty (30) round-turn trades of any contract number, per account, must be placed during the championship period in order to qualify for a championship award."),
        },
        {
            title: translate(props.lang, 'To be considered a contestant, you and your account must be registered in the championship.'),
            EN_content: translate(props.lang, "Your account gain will only be counted from the moment you register for the championship. You can register using the provided link. After registration, contestants will receive a personal link with analytics and gain information. The top ten participants with the highest gain at the moment will be displayed on the Standings page."),
        },
    ]

    const [selectedContent2, setSelectedContent2] = React.useState(0);

    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);
    const fireFlyArray = Array(100).fill(0);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 4.5 : window.innerWidth > 677 ? 2.5 : 1.3,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    const settings2 = {
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 5 : window.innerWidth > 677 ? 3 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='position-relative rules'>
                {/* <div class="">
                    {fireFlyArray.map((x, i) =>
                        <div class="circle-container">
                            <div class="circle"></div>
                        </div>
                    )}
                </div> */}
                <div className='py-5 position-relative'>
                    <div className='rulesContent'>
                        <div className="row justify-content-center align-items-center mx-0 pt-5 position-relative">
                            <div className='col-12 col-md-12 col-lg-10 text-center text-lg-left'>
                                <div className='mt-5 pt-5'>
                                    <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Welcome to the ULTIMATE PINNACLE TRADING BATTLE ROYALE!")}</span>
                                </div>

                                <div className='pt-2'>
                                    <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "To participate in the championship, please make sure to follow the following rules:")}</span>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center align-items-start w-100 mx-0 py-5'>
                            <div className='col-12 col-md-6 col-lg-5 text-left mb-0 mb-md-4 pt-5'>
                                <Accordion preExpanded={[0]} className="pt-2 text-left borderTopWhite">
                                    {faqAccordion.map((item, i) => (
                                        i >= 4 ? "" :
                                            <AccordionItem key={i} uuid={i} className={i === selectedContent2 ? "mb-0 mb-md-4 accordion-item borderBottomWhite" : "mb-0 mb-md-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent2(i)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    <span className={i === selectedContent2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        {item.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className={i === selectedContent2 ? "selectedExpandIcon" : "nonselectedExpandIcon"}>
                                                                    <FaAngleUp className="h3 my-0 accordionIcon text-white" style={{ display: i === selectedContent2 ? "block" : "none" }} />
                                                                    <FaAngleDown className="h3 my-0 accordionIcon" style={{ display: i === selectedContent2 ? "none" : "block" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel style={{ display: i === selectedContent2 ? "block" : "none" }}>
                                                    <div className="row justify-content-start align-items-center pt-3">
                                                        <div className="col-12">
                                                            <span className="accordionContent">
                                                                {props.lang === "ZH_CN" && item.ZH_content && item.ZH_content ?
                                                                    ReactHtmlParser(item.ZH_content) :
                                                                    ReactHtmlParser(item.EN_content)
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>

                            <div className='col-12 col-md-6 col-lg-5 text-left mb-4 pt-0 pt-md-5'>
                                <Accordion preExpanded={[0]} className="pt-2 text-left borderTopWhite">
                                    {faqAccordion.map((item, i) => (
                                        i < 4 ? "" :
                                            <AccordionItem key={i} uuid={i} className={i === selectedContent2 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent2(i)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    <span className={i === selectedContent2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        {item.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className={i === selectedContent2 ? "selectedExpandIcon" : "nonselectedExpandIcon"}>
                                                                    <FaAngleUp className="h3 my-0 accordionIcon text-white" style={{ display: i === selectedContent2 ? "block" : "none" }} />
                                                                    <FaAngleDown className="h3 my-0 accordionIcon" style={{ display: i === selectedContent2 ? "none" : "block" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel style={{ display: i === selectedContent2 ? "block" : "none" }}>
                                                    <div className="row justify-content-start align-items-center pt-3">
                                                        <div className="col-12">
                                                            <span className="accordionContent">
                                                                {props.lang === "ZH_CN" && item.ZH_content && item.ZH_content ?
                                                                    ReactHtmlParser(item.ZH_content) :
                                                                    ReactHtmlParser(item.EN_content)
                                                                }

                                                                <img src={mathCal} style={{ display: i === 4 ? "block" : "none" }} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className='rulesBgDecPos1'>
                        <img src={require('../assets/img/rules/rulesBgDec1.png').default} className="img-fluid" />
                    </div>

                    <div className='rulesBgDecPos2'>
                        <img src={require('../assets/img/rules/rulesBgDec2.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 contactUsBg">
                    <div className='col-11 col-md-11 col-lg-5'>
                        <div className='text-center mb-5 pb-5'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Enroll now and rise to the challenge")}</span>
                            <div className='pt-2'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Contact us if you have any questions. Let the trading begin!")}</span>
                            </div>
                            <div className='position-relative'>
                                <div className='participateBtnBg mx-auto'>
                                    <a href="/contact-us" className='footerTextHref'>
                                        <div className='participateBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                                            <span className='font-CN-Bold'>CONTACT US</span>
                                            <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                                        </div>
                                    </a>
                                </div>
                                <div className='buttonGradientCenterAnim mx-auto' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Rules);
