import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { Input } from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ContactUs(props) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);
    const fireFlyArray = Array(100).fill(0);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 4.5 : window.innerWidth > 677 ? 2.5 : 1.3,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    const settings2 = {
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 5 : window.innerWidth > 677 ? 3 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='position-relative contactUs'>
                {/* <div class="">
                    {fireFlyArray.map((x, i) =>
                        <div class="circle-container">
                            <div class="circle"></div>
                        </div>
                    )}
                </div> */}
                <div className='py-5'>
                    <div className="row justify-content-center align-items-center mx-0 pt-5 position-relative">
                        <div className='col-12 col-md-12 col-lg-11 text-left'>
                            <div className="row justify-content-center align-items-start mx-auto py-5 mt-5 px-0" style={{ maxWidth: "1600px" }}>
                                <div className='col-12 col-md-8 col-lg-3 text-center text-lg-left mb-5 pt-3'>
                                    <div className='mt-3'>
                                        <span className="font-CN-Bold sectionTitleText2 text-white">{translate(props.lang, "Thank you for your interest in the ")}</span>
                                        <span className="font-CN-Bold sectionTitleText2 text-gold">{translate(props.lang, "Ultimate Pinnacle Trading Battle Royale!")}</span>

                                        <div className='pt-4 d-flex justify-content-center justify-content-lg-start align-items-center'>
                                            <div className='socialMediaBg p-2'>
                                                <FaFacebookF className='socialMediaIcon' fontSize={18} />
                                            </div>
                                            <div className='socialMediaBg p-2 ml-3'>
                                                <FaInstagram className='socialMediaIcon' fontSize={20} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="copy-box two d-none d-lg-block">
                                    <div class="inner">
                                        <div class="line right">
                                            <div class="scanner"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-md-10 col-lg-8 text-center text-lg-left mb-4 pt-4'>
                                    <div className='pl-0 pl-md-4 pl-lg-0'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Our team is committed to providing all participants with a premium-class trading experience.")}</span>
                                        <br /><br />
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "If you have any questions about the registration process or the competition, please fill out the form below with your contact information.")}</span>
                                        <br /><br />
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "A dedicated personal manager will reach out to you shortly to guide you through each stage of registration and answer any questions you may have.")}</span>
                                        <br /><br />
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Join now and become a part of the Ultimate Pinnacle Trading Battle Royale!")}</span>

                                        <div className='pt-5 d-block d-lg-flex justify-content-start align-items-center'>
                                            <div className='d-flex justify-content-center justify-content-lg-start align-items-center mb-4 mb-lg-0'>
                                                <img src={require('../assets/img/contactUs/email.png').default} className="img-fluid contactUsIconSize" />
                                                <span className="font-CN-Regular sectionDescText text-white ml-2">{translate(props.lang, "admin@upfxtrade.com")}</span>
                                            </div>

                                            <div className='d-flex justify-content-center justify-content-lg-start align-items-center ml-0 ml-lg-5'>
                                                <img src={require('../assets/img/contactUs/time.png').default} className="img-fluid contactUsIconSize" />
                                                <span className="font-CN-Regular sectionDescText text-white ml-2">{translate(props.lang, "9:00 a.m. - 6:00 p.m.")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mx-0 position-relative pb-5">
                    <div className="col-12 col-lg-11 text-center">
                        <div className='contactUsBox py-5 mb-5'>
                            <div className="row justify-content-center mx-0 position-relative py-5">
                                <div className='col-11 col-md-5 col-lg-5 mb-5'>
                                    <div className='text-left'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Name")}</span> <br />
                                        <div className='pt-3'>
                                            <Input
                                                placeholder="Enter Your Name"
                                                type="text"
                                                name="name"
                                                // value={values.name}
                                                // onChange={handleChange("name")}
                                                className="contactUs-textBox"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-5 col-lg-5 mb-5'>
                                    <div className='text-left'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Email")}</span> <br />
                                        <div className='pt-3'>
                                            <Input
                                                placeholder="Enter Your Email"
                                                type="text"
                                                name="email"
                                                // value={values.name}
                                                // onChange={handleChange("name")}
                                                className="contactUs-textBox"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-5 col-lg-5 mb-5'>
                                    <div className='text-left'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Phone Number")}</span> <br />
                                        <div className='pt-3'>
                                            <Input
                                                placeholder="+60"
                                                type="text"
                                                name="phoneNum"
                                                // value={values.name}
                                                // onChange={handleChange("name")}
                                                className="contactUs-textBox"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-5 col-lg-5 mb-5'>
                                    <div className='text-left'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Your Trading Experiencwe")}</span> <br />
                                        <div className='pt-3'>
                                            <Input
                                                placeholder="Enter Your Name"
                                                type="text"
                                                name="name"
                                                // value={values.name}
                                                // onChange={handleChange("name")}
                                                className="contactUs-textBox"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-10 mb-5'>
                                    <div className='text-left'>
                                        <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Messages")}</span> <br />
                                        <div className='pt-3'>
                                            <textarea
                                                placeholder="Your Message"
                                                rows="8"
                                                name="message"
                                                className="contactUs-textArea"
                                            // value={values.message}
                                            // onChange={handleChange("message")}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-10 mb-5 px-5'>
                                    <div className='d-flex justify-content-start align-items-start text-left'>
                                        <Input
                                            type="checkbox"
                                            // onChange={(e) => console.log(e.target.checked)}
                                            className='contactUs-checkBox'
                                        />
                                        <div className='ml-2'>
                                            <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "I have read Privacy Policy and I accept them.")}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-11 col-md-10 mb-5 text-left'>
                                    <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Ultimate Pinnacle needs your contact info to contact you about our products and services. You may unsubscribe from these communications at any time. Please review our Privacy Policy to learn more.")}</span>
                                </div>

                                <div className='col-10 mb-5'>
                                    <div className='position-relative'>
                                        <div className='enrollBtnBg mx-auto'>
                                            <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                                                <span className='font-CN-Bold'>SEND</span>
                                                <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                                            </div>
                                        </div>
                                        <div className='buttonGradientCenterAnim left-0 right-0 mx-auto' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='contactBgDecPos1'>
                    <img src={require('../assets/img/contactUs/contactBgDec1.png').default} className="img-fluid" />
                </div>

                <div className='contactBgDecPos2'>
                    <img src={require('../assets/img/contactUs/contactBgDec2.png').default} className="img-fluid" />
                </div>

                <div className='contactBgDecPos3'>
                    <img src={require('../assets/img/contactUs/contactBgDec3.png').default} className="img-fluid" />
                </div>

            </div>


            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContactUs);
