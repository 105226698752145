import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";

import "./assets/css/custom.scss";
import "./assets/css/home.scss";
import "./assets/css/standings.scss";
import "./assets/css/aboutUs.scss";
import "./assets/css/rules.scss";
import "./assets/css/contactUs.scss";

import Standings from "views/Standings";
import AboutUs from "views/AboutUs";
import Rules from "views/Rules";
import Terms from "views/Terms";
import ContactUs from "views/ContactUs";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/standings" render={(props) => <Standings {...props} />} />
          <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
          <Route path="/rules" render={(props) => <Rules {...props} />} />
          <Route path="/terms" render={(props) => <Terms {...props} />} />
          <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />

          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
