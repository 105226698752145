/*eslint-disable*/
import React from "react";
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import { Input } from "reactstrap";

function DarkFooter(props) {
  return (
    <footer className="footer">
      <div className="row justify-content-center align-items-start mx-0">
        <div className="col-8 col-md-5 col-lg-2 text-center mb-5 mb-lg-4">
          <img src={require('../../assets/img/footerLogo.png').default} className="img-fluid footerLogoSize" />
        </div>

        <div className="col-12 col-md-12 col-lg-4 text-center " />

        <div className="col-11 col-md-11 col-lg-6">
          <div className="text-center">
            <div className="row justify-content-center align-items-center mx-0">
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/index' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "Home")}</span>
                </a>
              </div>
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/standings' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "Standings")}</span>
                </a>
              </div>
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/about-us' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "About Us")}</span>
                </a>
              </div>
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/rules' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "Rules")}</span>
                </a>
              </div>
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/terms' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "Terms")}</span>
                </a>
              </div>
              <div className="col-4 col-md-2 col-lg-2 text-center mb-4">
                <a href='/contact-us' className="footerTextHref">
                  <span className="navItemText font-CN-Regular">{translate(props.lang, "Contact Us")}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(DarkFooter);
