import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { translate } from 'utils/translate';

import { FaAngleDown, FaGlobe, FaSortDown } from "react-icons/fa";
import { HashLink } from 'react-router-hash-link';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const lang = [
  { label: "English", value: "EN" },
  { label: "简体中文", value: "ZH_CN" },
];

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [language, setLanguage] = useState(props.lang);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("navbar-black");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const selectLang = (item) => {
    setLanguage(item.value)
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item.value
    });
    localStorage.setItem('upfx-champ-lang', item.value);
  }

  const selectLanguageMobile = (item) => {
    setLanguage(item);
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item
    });
    localStorage.setItem('upfx-champ-lang', item);
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container style={{ maxWidth: "90%" }}>
          <div className="navbar-translate">
            <NavbarBrand href="/index" id="navbar-brand">
              <img className="navBarLogo" src={require("../../assets/img/logo.png").default} />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end align-items-center"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink className={window.location.pathname === "/index" ? "selectedNavItem" : ""}>
                  <div className="">
                    <a href='/index' className="navItemTextHref">
                      <span className="navItemText font-CN-Regular">{translate(props.lang, "Home")}</span>
                    </a>
                  </div>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={window.location.pathname === "/standings" ? "selectedNavItem" : ""}>
                  <a href='/standings' className="navItemTextHref">
                    <span className="navItemText font-CN-Regular">{translate(props.lang, "Standings")}</span>
                  </a>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={window.location.pathname === "/about-us" ? "selectedNavItem" : ""}>
                  <a href='/about-us' className="navItemTextHref">
                    <span className="navItemText font-CN-Regular">{translate(props.lang, "About Us")}</span>
                  </a>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={window.location.pathname === "/rules" ? "selectedNavItem" : ""}>
                  <a href='/rules' className="navItemTextHref">
                    <span className="navItemText font-CN-Regular">{translate(props.lang, "Rules")}</span>
                  </a>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={window.location.pathname === "/terms" ? "selectedNavItem" : ""}>
                  <a href='/terms' className="navItemTextHref">
                    <span className="navItemText font-CN-Regular">{translate(props.lang, "Terms")}</span>
                  </a>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className={window.location.pathname === "/contact-us" ? "selectedNavItem" : ""}>
                  <a href='/contact-us' className="navItemTextHref">
                    <span className="navItemText font-CN-Regular">{translate(props.lang, "Contact Us")}</span>
                  </a>
                </NavLink>
              </NavItem>

              {/* <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <FaGlobe fontSize={18} className="navbar-icon" />
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu right style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <DropdownItem onClick={() => selectLanguageMobile('EN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>English</DropdownItem>
                      <DropdownItem onClick={() => selectLanguageMobile('ZH_CN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>简体中文</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem> */}

              {/* <NavItem>
                <NavLink href="#">
                  <div className="learnMoreBtn py-3 px-4">
                    <span className="font-CN-Regular text-white">{translate(props.lang, "Learn More")}</span>
                  </div>
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(IndexNavbar);

