import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleLeft, FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutUs(props) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow Wnext cursor-pointer" id="nextArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <FaAngleRight fontSize={30} color='#fff' />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow Wprev cursor-pointer" id="prevArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <FaAngleLeft fontSize={30} color='#fff' />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = React.useState(0);
    const fireFlyArray = Array(100).fill(0);

    const settings = {
        dots: window.innerWidth > 667 ? false : true,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='position-relative aboutUs'>
                {/* <div class="">
                    {fireFlyArray.map((x, i) =>
                        <div class="circle-container">
                            <div class="circle"></div>
                        </div>
                    )}
                </div> */}

                <div className='ourStorySection position-relative'>
                    <div className="row justify-content-center align-items-start mx-auto pt-5 px-0" style={{ maxWidth: "1600px" }}>
                        <div className='col-11 col-lg-2 text-center text-lg-left mb-5'>
                            <div className='mt-5 mt-lg-3 pt-5 pt-lg-3'>
                                <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "OUR STORY ")}</span>
                            </div>
                        </div>

                        <div className='col-12 col-lg-1 text-center mb-0 mb-lg-5'>
                            <div class="copy-box2 two d-none d-lg-block">
                                <div class="inner">
                                    <div class="line right">
                                        <div class="scanner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-11 col-md-6 col-lg-4 text-center text-lg-left mb-4'>
                            <div className='pl-0 pl-md-4 pl-lg-0 pt-0 pt-lg-4'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "The Ultimate Pinnacle Trading Battle Royale is a new and exciting competition for traders around the world. The competition is hosted by Ultimate Pinnacle Limited, a subsidiary of Lepanto Financial Advisers Limited, which has been providing comprehensive and integrated solutions in the financial sector since 2003. With our 17 years of experience in the industry, we have decided to launch this championship to focus on the forex brokerage industry and offer traders a unique opportunity to test their skills.")}</span>
                            </div>
                        </div>

                        <div className='col-11 col-md-6 col-lg-4 text-center text-lg-left mb-4'>
                            <div className='pr-0 pr-md-4 pr-lg-0 pt-0 pt-lg-4'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "We believe that this competition will help traders measure and compare their trading skills against their peers and identify and inspire the next generation of Forex enthusiasts. We are committed to providing our participants with a fair, transparent, and inclusive competition, judged solely on the trading decisions and returns made by the individual trader.")}</span>
                            </div>
                        </div>
                    </div>

                    <div className='aboutBgDec1'>
                        <img src={require('../assets/img/aboutUs/aboutBgDec1.png').default} className="img-fluid" />
                    </div>

                    <div className='aboutBgDec2'>
                        <img src={require('../assets/img/aboutUs/aboutBgDec2.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className='whyUPTBRsection pb-5 mb-5 pt-5 mt-5 pt-lg-0 mt-lg-0'>
                    <div className='row justify-content-center align-items-center mx-0 mb-5 pb-5 pt-5 pt-lg-0'>
                        <div className='col-12 col-md-10 col-lg-11'>

                            <Slider {...settings}>
                                <div className={imageIndex === 0 ? "activeUPTBR" : "inactiveUPTBR"} >
                                    <div className='position-relative mb-5'>
                                        <div className='whyBox px-5 py-5 advantageBoxTop1' />
                                        <div className='whyBox px-5 py-5 advantageBoxTop2' />

                                        <div className='whyBox px-2 py-5 advantageBoxTop'>
                                            <div className='px-0 px-md-4'>
                                                <div className='text-center'>
                                                    <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "What are your expectations for the UPTBR 2023?")}</span>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "We expect the UPTBR 2023 to be a highly competitive and exciting event that will attract some of the most talented traders from around the world. We are confident that this competition will provide our participants with a unique opportunity to test their skills and compete among the best in the industry.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={imageIndex === 1 ? "activeUPTBR" : "inactiveUPTBR"} >
                                    <div className='position-relative mb-5'>
                                        <div className='whyBox px-5 py-5 advantageBoxTop1' />
                                        <div className='whyBox px-5 py-5 advantageBoxTop2' />

                                        <div className='whyBox px-2 py-5 advantageBoxTop'>
                                            <div className='px-0 px-md-4'>
                                                <div className='text-center'>
                                                    <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "Why do you think it's necessary to hold something like UPTBR 2023 now?")}</span>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "We believe that it is essential to hold events like the UPTBR 2023 to help traders measure and compare their trading skills against their peers and identify and inspire the next generation of Forex enthusiasts. We are committed to providing our participants with a fair, transparent, and inclusive competition that will be judged solely on the trading decisions and returns made by the individual trader.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={imageIndex === 2 ? "activeUPTBR" : "inactiveUPTBR"} >
                                    <div className='position-relative mb-5'>
                                        <div className='whyBox px-5 py-5 advantageBoxTop1' />
                                        <div className='whyBox px-5 py-5 advantageBoxTop2' />

                                        <div className='whyBox px-2 py-5 advantageBoxTop'>
                                            <div className='px-0 px-md-4'>
                                                <div className='text-center'>
                                                    <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "How would you describe the championship yourself?")}</span>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "I would describe the Ultimate Pinnacle Trading Battle Royale as a fair, transparent, and inclusive competition that is open to traders around the world. The competition will be judged solely on the trading decisions and returns made by the individual trader, ensuring a level playing field for all participants.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={imageIndex === 3 ? "activeUPTBR" : "inactiveUPTBR"} >
                                    <div className='position-relative mb-5'>
                                        <div className='whyBox px-5 py-5 advantageBoxTop1' />
                                        <div className='whyBox px-5 py-5 advantageBoxTop2' />

                                        <div className='whyBox px-2 py-5 advantageBoxTop'>
                                            <div className='px-0 px-md-4'>
                                                <div className='text-center'>
                                                    <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "Any words of encouragement for participants?")}</span>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "We encourage all traders to take advantage of this opportunity to showcase their trading skills and compete among the best in the industry. Just stick to your strategies and normal trading behavior, and while nobody can predict the future, this championship gives you the chance to gain recognition and win a substantial prize, simply for what you are already doing.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>


                <div className="row justify-content-center align-items-center mx-0 position-relative">
                    <div className='col-10'>
                        <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "what awaits ")}</span>
                        <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "UPTBR 2023 participants?")}</span> <br />
                    </div>
                    <div className='col-12 col-md-10 col-lg-5'>
                        <div className='d-flex justify-content-start pt-5'>
                            <div class="copy-box2 two d-none d-lg-block" style={{ height: "400px" }}>
                                <div class="inner">
                                    <div class="line right">
                                        <div class="scanner"></div>
                                    </div>
                                </div>
                            </div>

                            <div className='pl-0 pl-md-0 pl-lg-4'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "All the winner needs to do is to show the maximum gain by trading with the initial deposit made.")}</span> <br /><br />
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Trade any instruments, use any fair strategies, follow the rules, and you will have a chance to go down in history as one of the best traders.")}</span> <br /><br />
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "UPTBR 2023 is all about fair and uncompromising competition for those traders who consider themselves the best in business.")}</span>

                                <div className='pt-5'>
                                    <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "COULD IT BE YOU")}</span> <br />
                                    <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "who takes the coveted position of the very first winner of the Ultimate Pinnacle Trading Battle Royale?")}</span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-md-9 col-lg-5'>
                        <div className='position-relative pt-5 pt-lg-4'>
                            <img src={require('../assets/img/aboutUs/robotImg.png').default} className="img-fluid robotBody" />
                        </div>
                    </div>

                    <div className='aboutBgDec3'>
                        <img src={require('../assets/img/aboutUs/aboutBgDec3.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mx-0 pt-5 mt-5 position-relative">
                    <div className='col-12 col-md-12 col-lg-10 text-center' >
                        <div className='my-5'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "OUR GUARANTEES")}</span>
                        </div>

                        <div className='pt-1'>
                            <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 text-left" >
                                <div className='col-12 col-md-6 col-lg-4 my-4'>
                                    <div className='position-relative mb-5'>
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop1' />
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop2' />

                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop'>
                                            <div>
                                                <div className='text-left'>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "When you open an account with Ultimate Pinnacle Limited, you can be sure that your account(s) will be subject to the appropriate licenses and are protected by the relevant regulator.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='guaranteeBoxIconBox'>
                                            <img src={require('../assets/img/aboutUs/guaranteeIcon1.png').default} className="img-fluid guaranteeBoxIconSize" />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 col-lg-4 my-4'>
                                    <div className='position-relative mb-5'>
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop1' />
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop2' />

                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop'>
                                            <div>
                                                <div className='text-left'>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "You can replenish your account at any time and withdraw funds in any amount without any restrictions.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='guaranteeBoxIconBox'>
                                            <img src={require('../assets/img/aboutUs/guaranteeIcon2.png').default} className="img-fluid guaranteeBoxIconSize" />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 col-lg-4 my-4'>
                                    <div className='position-relative mb-5'>
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop1' />
                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop2' />

                                        <div className='guaranteeBox px-5 py-5 advantageBoxTop'>
                                            <div>
                                                <div className='text-left'>
                                                    <div className='pt-3'>
                                                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Winners will receive a share of a massive $30,000 cash prize with no additional conditions.")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='guaranteeBoxIconBox'>
                                            <img src={require('../assets/img/aboutUs/guaranteeIcon3.png').default} className="img-fluid guaranteeBoxIconSize" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='aboutBgDec4'>
                        <img src={require('../assets/img/aboutUs/aboutBgDec4.png').default} className="img-fluid" />
                    </div>

                    <div className='aboutBgDec5'>
                        <img src={require('../assets/img/aboutUs/aboutBgDec5.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 contactUsBg">
                    <div className='col-11 col-md-11 col-lg-5'>
                        <div className='text-center mb-5 pb-5'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Enroll now and rise to the challenge")}</span>
                            <div className='pt-2'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Contact us if you have any questions. Let the trading begin!")}</span>
                            </div>
                            <div className='position-relative'>
                                <div className='enrollBtnBg mx-auto'>
                                    <a href="/contact-us" className='footerTextHref'>
                                        <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-1 py-3 mt-4 '>
                                            <span className='font-CN-Bold'>CONTACT US</span>
                                            <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                                        </div>
                                    </a>
                                </div>
                                <div className='buttonGradientCenterAnim mx-auto' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(AboutUs);
