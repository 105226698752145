import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Terms(props) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const fireFlyArray = Array(100).fill(0);

    return (
        <>
            <IndexNavbar />
            <div className='position-relative rules pt-0 pt-md-4'>
                {/* <div class="">
                    {fireFlyArray.map((x, i) =>
                        <div class="circle-container">
                            <div class="circle"></div>
                        </div>
                    )}
                </div> */}
                <div className='py-5 position-relative'>
                    <div className='row justify-content-center align-items-center rulesContent mx-0 py-5 mt-0 mt-lg-5'>
                        <div className='col-12 col-md-11 col-lg-10'>
                            <div className=''>
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Welcome to the Ultimate Pinnacle Trading Battle Royale 2023, organized and sponsored by Ultimate Pinnacle Limited. These terms and conditions apply to the Championship and by participating, you agree to comply with these rules.")}</span>
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "1. Interpretation")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.1. Championship - Ultimate Pinnacle Trading Battle Royale 2023.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.2. Championship Period – time to time update.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.3. Championship Provider – Ultimate Pinnacle Limited, is a limited liability company established in Belize as an operating subsidiary within the Lepanto Group of companies (collectively “Ultimate Pinnacle”) under Registration No. 173474 and having its registered address at No. 1 Corner of Hutson Street and Marine Parade, Belize City, Belize.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.4. Account – Participant’s verified UPFX Ultimate account registered on one of the derivatives issuers providing the services under the “UPFX” trademark, also referred to as “UPFX”.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.5. UPFX (also “CFD Issuer”) –")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.5.1. Ultimate Pinnacle Limited is a company authorized and regulated by the National Futures Association (NFA ID: 0554496);")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.5.2. Ultimate Pinnacle Limited is a company authorized and regulated by the FinCEN Money Services Businesses (MSB ID: 31000228678615)")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.5.3. Ultimate Pinnacle Limited, under Registration No. 173474 and having its registered address at No. 1 Corner of Hutson Street and Marine Parade, Belize City, Belize. Providing services through www.upfxtrade.com.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.6. Minimum deposit – a required minimum amount of own funds to be deposited in the Account in an amount of 100 USD or its equivalent.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.7. Daily Profit is calculated as follows:")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.8. Participant (also “You”, “Your”) – a participant of the Championship, who meets requirements described in these Terms.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.9. Terms and Conditions – these Terms and Conditions.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "1.10. Website – www.forexcup.com")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "2. Participation Requirements")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "2.1. You have the right to participate in the Championship subject to meeting the following requirements:")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(i) You have reached 18 years old or other legal age applicable in your residence country (but not less than 18 years old).;")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(ii) You are the client of UPFX and you hold an active Ultimate Account;")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(iii) You made a minimum deposit in your account. Additional funds can be deposited in your account at any time within the Championship Period.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(iv) You accepted these Terms;")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(v) You sent a request for registration of your account(s) in the Championship to championship@forexcup.com")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "2.2. Championship Provider reserves the right in its sole discretion to accept or decline a registration of any Participant in the Championship.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "2.3. Participant and Participant’s Account(s) should be registered by the Championship Provider.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "3. General Terms")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.1. Participants may register to the Championship up to the last day of the Championship Period.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.2. Subject to the Championship Provider’s special decision the Participant may register more than one Account for the Championship if the Participant is using different trading strategies and/or there are no attempts by the Participant to manipulate the Championship process and/or results.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.3. Trading must be conducted in the Participant’s Account(s). A minimum of thirty (30) round-turn trades of any contract number, per account, must be placed during the Championship Period in order for the Participant to qualify for a Championship Award.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.4. The winner of the Championship is the Participant which received the largest Total Gain during the Championship Period.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.5. Should the Participant have open positions at the time of the championship closure (21:59 on the end date of the campaign (GMT+8)), the Championship Provider shall calculate the financial result of such positions in its sole and absolute discretion subject to the prices and liquidity available in the respective UPFX trading platform.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.6. The results of the completed Championship cannot be revised.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.7. The Championship Provider has the right to solely amend, interpret or waive any rule or terms if such actions are undertaken in the best interests of the Championship.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "3.8. The Championship Provider has the right in its sole discretion and at any moment it deems fit to disqualify the Participant, shall the Championship Provider and/or UPFX determine that the Participant violates the agreement with UPFX, and/or is engaging or has engaged in using trading strategies or activities designed intentionally to exploit errors, bugs, loopholes in price quotations, order execution, statement generation, charging of fees and commissions, funds transfer and/or other delays, disruption, failure or malfunction of any software, hardware, transmission or communication system or software.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "4. Trading Terms")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "4.1. The Participant agrees to comply with terms and rules of the specific Account type as specified by UPFX and its other applicable rules and regulations. Please refer to the specific UPFX Trading Terms for Ultimate Accounts as provided by UPFX on the respective websites.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "4.2. The list of instruments as specified by trading on an UPFX Ultimate Account (among them FX Spot, Metals CFD, and Commodities CFD). Index CFDs, Stocks, and Crypto CFDs are not available to trade in all jurisdictions.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "4.3. Withdrawals from the Accounts participating in the Championship shall be processed as usual, subject to the Account and the Participant is compliant with requirements and terms of the specific type of the Account and applicable law. Withdrawal of part or all the minimum deposit by a participant may result in disqualification of the participant's account without any notice. This requirement does not apply to the withdrawal of profits from the account.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "4.4. All transfers, withdrawals, deposits connected with the Account and trading during the Championship are subject to charges as determined by UPFX’s terms. In case of any errors in order placement or execution, the Championship Provider makes a final decision in accordance with these Terms.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "4.5. The Participant acknowledges and agrees that the Championship Provider has the sole right to disqualify the Account of the Participant, which during the Championship attempted to benefit from any collusive or other trading irregularity or attempted to violate or violated any law or governing this Championship rule or regulation.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "5. Awards")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.1. At the end of the Championship, the top 3 profitable Participants receive a Crystal trophy. Awards are specified on the Website.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.2. The Top 3 Winners receive prizes from a pool fund up to 30,000 USD.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.3. Results of the Championship will be proclaimed on the Website.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.4. All money awards from the Championship are withdrawable to the personal bank or payment account of the Participant.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.5. If the Participant, who takes an award-place, breached these Terms, regulating terms of the Account or any other applicable to this Championship rules, such Participant will be disqualified and Championship Provider has the right to void an award.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "5.6. Winners of the Championship are solely responsible for any taxation or charges on awards.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "6. Authorization")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "6.1. The Participant gives an authorization to the Championship Provider without any recourse or compensation to:")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(i) obtain information about his Account’s transactions records; his Account activity and other information (including. but without limitation, Account’s number); name and surname; email and other details of the participation in the Championship in order to run a Championship, monitoring activity and transfer a prize payout to the winners;")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(ii) show and announce publicly the Participant’s name and surname, residence country, photo, Account number, trading results and other details of the participation in the Championship on the Website or any other publication for declaring the results of the Championship;")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "(iii) Interview the Participants to create video, audio, and other content with the Participants, publish interviews and content on the Website, UPFX resources, and social media or in any other place for promotional purposes. The interview, audio, video, text, and other materials created by the Championship Provider in respect to the Championship shall belong to the Championship Provider and is its intellectual property irrespective whether it contains references to, images, videos of the Participant or not.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "6.2. All personal information during the Championship obtaining and processing by the UPFX and Championship Provider are treated in accordance with Personal Data Protection Act 2010 (MY) (“PDPA”) and other applicable in your residence country personal data protection laws.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "7. Representations")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "The Participant represents that he/she is of legal age and in its residence, the country is allowed to open an Account and participate in the Championship. The Participant represents that he and his account are complying with the applicable law and obtained all necessary licenses and authorizations (if required by applicable law) to participate in this Championship.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "8. Indemnification")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "8.1. The Participant agrees to indemnify UPFX and the Championship Provider and their affiliates, employees and hold harmless from any claim, demand, expenses, or costs (including legal fees, fines or penalties) incurred or suffered due to or arising out of Participant’s actions.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "8.2. Neither the Championship Provider nor UPFX shall not be responsible for any direct or indirect damage or loss, incurred by the Participant, during the Championship and for the results of the Championship.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "8.3. UPFX shall not be responsible for any delays or failures in the acceptance or transmission of orders due to any failure of transmission, computers (software or hardware) or any other event that is beyond UPFX’s reasonable control.")}</span><br />
                            </div>

                            <div className='pt-4'>
                                <span className="font-CN-Bold sectionDescText text-white">{translate(props.lang, "9. Miscellaneous")}</span> <br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "9.1. In the event of any dispute or misinterpretation of these Terms or the Championship, the Championship Provider shall resolve such disputes or misinterpretations and shall act in good faith and deem fit and proper. The Championship Provider’s decision is final.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "9.2. Championship Provider reserves the right to change the Championship period or any other detail or aspect of the Championship and suspend, postpone, or cease the Championship at any time and at its sole discretion. Any changes shall be posted on the Championship Provider’s website and from the moment of such change notification posted on the Championship Provider’s website it is considered that the Participant accepted such changes. The Participant agrees that UPFX or the Championship Provider is not liable under any circumstances for any consequences of changes of the Championship.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "9.3. In the event of translation of these Terms, the English version shall prevail under any other translated Terms to another language.")}</span><br />
                                <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "9.4. These Terms and the disputes arising from the Championship shall be governed by and enforced in all respects by the laws of Panama.")}</span><br />
                            </div>
                        </div>

                    </div>

                    <div className='termsBgDecPos1'>
                        <img src={require('../assets/img/terms/termsBgDec1.png').default} className="img-fluid" />
                    </div>

                    <div className='termsBgDecPos2'>
                        <img src={require('../assets/img/terms/termsBgDec2.png').default} className="img-fluid" />
                    </div>

                    <div className='termsBgDecPos3'>
                        <img src={require('../assets/img/terms/termsBgDec3.png').default} className="img-fluid" />
                    </div>


                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Terms);
