import { UPDATE_LANGUAGE } from "./action";
import { current_website } from "utils/api";

const defaultState = {
    lang:  localStorage.getItem('upfx-champ-lang') && localStorage.getItem('upfx-champ-lang') ? localStorage.getItem('upfx-champ-lang') : "EN"
};

function i18nReducer(state = defaultState, action) {
    console.log("STATE", state);
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.lang,
                // lang: state.lang,
            };
        default:
            return state;
    }
}

export default i18nReducer;