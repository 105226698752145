import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Standings(props) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);
    const fireFlyArray = Array(100).fill(0);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 4.5 : window.innerWidth > 677 ? 2.5 : 1.3,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    const settings2 = {
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 5 : window.innerWidth > 677 ? 3 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='position-relative standings'>
                {/* <div class="">
                    {fireFlyArray.map((x, i) =>
                        <div class="circle-container">
                            <div class="circle"></div>
                        </div>
                    )}
                </div> */}
                <div className="row justify-content-center align-items-center mx-0 pt-5 position-relative">
                    <div className='col-12 col-md-12 col-lg-10 text-left'>
                        <div className='mt-5 pt-5'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "CURRENT STANDING")}</span>
                        </div>

                        <div className='pt-2'>
                            <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Join the ultimate trading battle and showcase your skills in the ULTIMATE PINNACLE TRADING BATTLE ROYALE.")}</span>
                        </div>

                        <div className='standingSection py-5 my-4'>
                            <div className='row justify-content-center align-items-center pt-4'>
                                <div className='col-11 col-md-11 col-lg-8 mb-3'>
                                    <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "ULTIMATE PINNACLE TRADING BATTLE ROYALE 2023")}</span>
                                </div>
                                <div className='col-11 col-md-11 col-lg-3 text-left text-md-right'>
                                    <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Last update: April 14, 2023")}</span>
                                </div>
                            </div>

                            <div className='row justify-content-center align-items-center'>
                                <div className='col-11 pt-5 pb-3'>
                                    <div className="table-responsive text-white">
                                        <table className="table text-white">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "RANK")}</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "NAME")}</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "GAIN")}</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "LOCATION")}</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "1")}</span>
                                                    </th>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Seyit Altuntas")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "290.76%")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Turkey")}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "1")}</span>
                                                    </th>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Seyit Altuntas")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "290.76%")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Turkey")}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "1")}</span>
                                                    </th>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Seyit Altuntas")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "290.76%")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Turkey")}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "1")}</span>
                                                    </th>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Seyit Altuntas")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "290.76%")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="font-CN-Regular imageBoxDesc text-white">{translate(props.lang, "Turkey")}</span>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='standingBgDecPos1'>
                        <img src={require('../assets/img/standings/standinbgTableBgDec.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className='position-relative'>
                    <div className="row justify-content-center align-items-center mx-0 pt-5 position-relative">
                        <div className='col-12 col-md-12 col-lg-11 text-left'>
                            <div className="row justify-content-center align-items-center mx-0 mx-lg-auto pt-5 mt-5 px-0" style={{ maxWidth: "1600px" }}>
                                <div className='col-12 col-lg-3 text-center text-lg-left mb-5'>
                                    <div className='mt-3'>
                                        <span className="font-CN-Bold sectionTitleText2 text-white">{translate(props.lang, "To participate, ")}</span>
                                        <span className="font-CN-Bold sectionTitleText2 text-gold">{translate(props.lang, "simply pay $100 ")}</span>
                                        <span className="font-CN-Bold sectionTitleText2 text-white">{translate(props.lang, "and ")}</span>
                                        <span className="font-CN-Bold sectionTitleText2 text-gold">{translate(props.lang, "choose from two categories")}</span>

                                        <div className='position-relative' style={{ minHeight: "100px" }}>
                                            <div className='participateBtnBg mx-auto mx-lg-0'>
                                                <a href="https://client.upfxtrade.com/register/trader" className='footerTextHref' target='_blank'>
                                                    <div className='participateBtn d-flex align-items-center justify-content-center mx-auto px-1 py-3 mt-4 '>
                                                        <span className='font-CN-Bold'>PARTICIPATE NOW</span>
                                                        <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='buttonGradientCenterAnim mx-auto mx-lg-0 d-block d-lg-none' />
                                            <div className='buttonGradientLeftAnim mx-auto mx-lg-0 d-none d-lg-block' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-2 mb-5'>
                                    <div class="copy-box2 two d-none d-lg-block mx-auto">
                                        <div class="inner">
                                            <div class="line right">
                                                <div class="scanner"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6 col-md-6 col-lg-3 text-center mb-4'>
                                    <div className='text-center'>
                                        <img src={require('../assets/img/standings/participateIcon1.png').default} className="img-fluid pariticipateIconSize" />
                                        <div className='pt-3'>
                                            <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "balance ")}</span>
                                            <span className="font-CN-Regular sectionDescText text-gold">{translate(props.lang, "below $100")}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6 col-md-6 col-lg-3 text-center mb-4'>
                                    <div className='text-center'>
                                        <img src={require('../assets/img/standings/participateIcon2.png').default} className="img-fluid pariticipateIconSize" />
                                        <div className='pt-3'>
                                            <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "balance ")}</span>
                                            <span className="font-CN-Regular sectionDescText text-gold">{translate(props.lang, "$100 and above")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='standingBgDecPos2'>
                        <img src={require('../assets/img/standings/standinbgTableBgDec2.png').default} className="img-fluid" />
                    </div>
                    <div className='standingBgDecPos3'>
                        <img src={require('../assets/img/standings/standinbgTableBgDec3.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mx-0 pt-5 mt-5 position-relative robotBg">
                    <div className='col-12 col-md-9 col-lg-5 mb-4'>
                        <div className='position-relative'>
                            <img src={require('../assets/img/standings/robotBody.png').default} className="img-fluid robotBody" />
                            <img src={require('../assets/img/standings/light1.png').default} className="img-fluid light1Pos lightFade" />
                        </div>
                    </div>

                    <div className='col-12 col-md-10 col-lg-5'>
                        <div className='mt-3 text-center text-lg-left'>
                            <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "Join the contest anytime, ")}</span>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "during the contest start and show us what you've got!")}</span> <br />
                        </div>

                        <div className='pt-2 text-center text-lg-left'>
                            <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Weekly and monthly prizes will differ, so be sure to stay on top of the leader board and keep your eyes on the ultimate prize.")}</span>
                        </div>
                    </div>
                    <div className='standingBgDecPos4'>
                        <img src={require('../assets/img/standings/standinbgTableBgDec4.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className='prizeBg d-flex justify-content-center align-items-center'>
                    <div className='row justify-content-center align-items-center mx-0 py-5 my-5 text-center'>
                        <div className='col-12 col-md-10 col-lg-9'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "will ")}</span>
                            <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "you ")}</span>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "be the one to take home the ")}</span>
                            <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "grand prize and personalized Crystal Trophy?")}</span>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 contactUsBg">
                    <div className='col-11 col-md-11 col-lg-5'>
                        <div className='text-center mb-5 pb-5'>
                            <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Enroll now and rise to the challenge")}</span>
                            <div className='pt-2'>
                                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Contact us if you have any questions. Let the trading begin!")}</span>
                            </div>
                            <div className='position-relative'>
                                <div className='participateBtnBg mx-auto'>
                                    <a href="/contact-us" className='footerTextHref'>
                                        <div className='participateBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                                            <span className='font-CN-Bold'>CONTACT US</span>
                                            <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                                        </div>
                                    </a>
                                </div>
                                <div className='buttonGradientCenterAnim mx-auto' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Standings);
