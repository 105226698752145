import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Index(props) {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const [imageIndex, setImageIndex] = React.useState(0);
  const [imageIndex2, setImageIndex2] = React.useState(0);
  const fireFlyArray = Array(100).fill(0);

  const settings = {
    dots: false,
    infinite: false,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: false,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 4.5 : window.innerWidth > 677 ? 2.5 : 1.3,
    centerMode: false,
    centerPadding: 0,
    beforeChange: (current, next) => { setImageIndex(next) },
  };

  const settings2 = {
    dots: false,
    infinite: true,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: true,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 5 : window.innerWidth > 677 ? 3 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    swipe: true,
    beforeChange: (current, next) => { setImageIndex2(next) },
  };

  return (
    <>
      <IndexNavbar />
      <div className='position-relative home'>
        {/* <div class="">
          {fireFlyArray.map((x, i) =>
            <div class="circle-container">
              <div class="circle"></div>
            </div>
          )}
        </div> */}
        <div className="row justify-content-center mx-0">
          <div className="col-12 text-center">
            <div className='row justify-content-center align-items-center headerSection'>
              <div className='col-12 col-md-11 col-lg-9'>
                <div className=''>
                  <span className="font-CN-Bold headerTitle text-white">{translate(props.lang, "Shape Your Future")}</span>
                  <br />
                  <span className="font-CN-Thin headerSubTitle text-white">{translate(props.lang, "Take Control of Your Life by Making the Right Choices in Every Moment!")}</span>
                  <br />
                  <div className='mt-3'>
                    <span className="font-CN-Thin headerDesc text-white">{translate(props.lang, "Join the contest at any time during the contest period and showcase your investment skills!")}</span>
                  </div>
                </div>
                <div className='position-relative'>
                  <div className='enrollBtnBg mx-auto'>
                    <a href="https://client.upfxtrade.com/register/trader" className='footerTextHref' target='_blank'>
                      <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                        <span className='font-CN-Bold'>ENROLL</span>
                        <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                      </div>
                    </a>
                  </div>
                  <div className='buttonGradientCenterAnim left-0 right-0 mx-auto' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mx-0 position-relative">
          <div className="col-11 text-center">
            <div className='row justify-content-center align-items-center prizeSection py-3'>
              <div className='col-10 col-md-8 col-lg-4 prizeBg py-5 mb-5'>
                <div className='py-5 my-5 text-left text-md-center text-lg-left'>
                  <span className="font-CN-Regular PrizeTopTitle text-white text-blue-shadow">{translate(props.lang, "Compete for a chance")}</span>
                  <br />
                  <span className="font-CN-Regular PrizeTopTitle text-white text-blue-shadow">{translate(props.lang, "to win a share of the massive")}</span>
                  <div className='mt-3'>
                    <span className="font-CN-Bold PrizeTitle text-gold">{translate(props.lang, "$30,000 ")}</span>
                    <span className="font-CN-Bold PrizeTitle text-white text-blue-shadow">{translate(props.lang, "Prize Pool")}</span>
                  </div>
                </div>
              </div>

              <div class="copy-box two d-none d-lg-block">
                <div class="inner">
                  <div class="line right">
                    <div class="scanner"></div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-11 col-lg-6'>
                <div className="row justify-content-center align-items-center mx-0">
                  <div className='col-10 col-md-6 mb-4'>
                    <div className=''>
                      <img src={require('../assets/img/home/prizeIcon1.png').default} className="img-fluid imageBoxImgSize" />
                      <div className='pt-3'>
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "Minimum deposit")}</span> <br />
                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Participate in our contests for just $100")}</span>
                      </div>
                    </div>
                  </div>

                  <div className='col-10 col-md-6 mb-4'>
                    <div className=''>
                      <img src={require('../assets/img/home/prizeIcon2.png').default} className="img-fluid imageBoxImgSize" />
                      <div className='pt-3'>
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "The championship period")}</span> <br />
                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Join our weekly and monthly contests anytime for your chance to win!")}</span>
                      </div>
                    </div>
                  </div>

                  <div className='col-10 col-md-6 mb-4'>
                    <div className=''>
                      <img src={require('../assets/img/home/prizeIcon3.png').default} className="img-fluid imageBoxImgSize" />
                      <div className='pt-3'>
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "Gain policy")}</span> <br />
                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Your ranking will be based on your Adjusted Rate on Investment (AROI)")}</span>
                      </div>
                    </div>
                  </div>

                  <div className='col-10 col-md-6 mb-4'>
                    <div className=''>
                      <img src={require('../assets/img/home/prizeIcon4.png').default} className="img-fluid imageBoxImgSize" />
                      <div className='pt-3'>
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "Signature awards")}</span> <br />
                        <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Personalized crystal CREST trophies for the top three places")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='BgDecPos1'>
            <img src={require('../assets/img/home/robotBgDec1.png').default} className="img-fluid" />
          </div>
        </div>

        <div className="row justify-content-center align-items-center mx-0 pt-5 mt-5 position-relative robotBg">
          <div className='col-12 col-md-9 col-lg-5'>
            <div className='position-relative'>
              <img src={require('../assets/img/home/robot1Body.png').default} className="img-fluid robotBody" />
              <img src={require('../assets/img/home/touch1.png').default} className="img-fluid touch1Pos lightFade1" />
              <img src={require('../assets/img/home/touch2.png').default} className="img-fluid touch2Pos lightFade1" />
            </div>
          </div>
          <div className='col-1' />

          <div className='col-12 col-md-10 col-lg-5'>
            <div className='mt-3 text-center text-lg-left'>
              <span className="font-CN-Bold enrollTitleText text-white">{translate(props.lang, "Look for your choice,")}</span> <br />
              <span className="font-CN-Bold enrollTitleText text-white">{translate(props.lang, "pick the ")}</span>
              <span className="font-CN-Bold enrollTitleText text-gold">{translate(props.lang, "best one,")}</span> <br />
              <span className="font-CN-Bold enrollTitleText text-white">{translate(props.lang, "then go with it")}</span>
            </div>

            <div className='position-relative'>
              <div className='enrollBtnBg mx-auto mx-lg-0'>
                <a href="https://client.upfxtrade.com/register/trader" className='footerTextHref' target='_blank'>
                  <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                    <span className='font-CN-Bold'>ENROLL</span>
                    <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                  </div>
                </a>
              </div>
              <div className='buttonGradientCenterAnim mx-auto mx-lg-0 d-block d-lg-none' />
              <div className='buttonGradientLeftAnim mx-auto mx-lg-0 d-none d-lg-block' />
            </div>
          </div>
          <div className='BgDecPos2'>
            <img src={require('../assets/img/home/robotBgDec2.png').default} className="img-fluid" />
          </div>
        </div>

        <div className="row justify-content-center align-items-center mx-0 pt-5 mt-5 position-relative">
          <div className='col-12 col-md-12 col-lg-9 text-center'>
            <div className='mt-3'>
              <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "ULTIMATE PINNACLE ")}</span>
              <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "TRADING BATTLE ROYALE")}</span> <br />
            </div>

            <div className='pt-4'>
              <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "A fresh and exciting addition to the online trading competition scene, backed by the trusted ownership of UPFXTrade. Here's your chance to showcase your trading skills and leave your mark in the world of trading")}</span>
              <br /><br />
              <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "UpFXTrade guarantees fair and reliable competition, allowing you to focus solely on your trading strategies and giving you the optimal opportunity to prove yourself as the Top Trader.")}</span>
            </div>

            <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 pt-lg-0 mt-lg-0 text-left" style={{ maxWidth: "1100px" }}>
              <div className='col-12 col-md-6 col-lg-6 my-4'>
                <div className='position-relative mb-5'>
                  <div className='advantagesBox px-5 py-5 advantageBoxBgLeft1' />
                  <div className='advantagesBox px-5 py-5 advantageBoxBgLeft2' />

                  <div className='advantagesBox px-3 py-1 px-md-5 py-md-5 advantageBoxLeft'>
                    <div>
                      <div className='d-flex align-items-center'>
                        <img src={require('../assets/img/home/advantageIcon1.png').default} className="img-fluid imageBoxIconSize mr-3" />
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "The Sky's the Limit")}</span> <br />
                      </div>

                      <div className='text-left'>
                        <div className='pt-3'>
                          <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Unleash Your Potential in the Ultimate Pinnacle Trading Championship. All you need is an UPFXTrade account, meet the minimum deposit, and comply with the rules to participate and showcase your skills.")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='position-relative mt-5'>
                  <div className='advantagesBox px-5 py-5 advantageBoxBgLeft1' />
                  <div className='advantagesBox px-5 py-5 advantageBoxBgLeft2' />

                  <div className='advantagesBox px-3 py-1 px-md-5 py-md-5 advantageBoxLeft'>
                    <div>
                      <div className='d-flex align-items-center'>
                        <img src={require('../assets/img/home/advantageIcon3.png').default} className="img-fluid imageBoxIconSize mr-3" />
                        <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "The Ultimate Rewards for Your Trading Success")}</span> <br />
                      </div>

                      <div className='text-left'>
                        <div className='pt-3'>
                          <ul>
                            <li>
                              <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Compete for different prizes in our weekly and monthly contests!")}</span>
                            </li>
                            <li>
                              <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Signature Crystal Trophies for the first three places")}</span>
                            </li>
                            <li>
                              <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Personal brand promotion through press releases, interviews, and articles")}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 col-lg-6 my-4'>
                <div className='paddingCol-2'>
                  <div className='position-relative d-flex justify-content-end'>
                    <div className='advantagesBox px-5 py-5 advantageBoxBgRight1' />
                    <div className='advantagesBox px-5 py-5 advantageBoxBgRight2' />

                    <div className='advantagesBox px-3 py-1 px-md-5 py-md-5 advantageBoxRight'>
                      <div>
                        <div className='d-flex align-items-center'>
                          <img src={require('../assets/img/home/advantageIcon2.png').default} className="img-fluid imageBoxIconSize mr-3" />
                          <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "Diversify Your Portfolio")}</span> <br />
                        </div>

                        <div className='text-left'>
                          <div className='pt-3'>
                            <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Trade Any Instrument with Ease. Choose from Spot FX, Index, Commodity, Stock & ETF, Metal, or Crypto CFDs* and start trading confidently.")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='position-relative d-flex justify-content-end mt-5'>
                    <div className='advantagesBox px-5 py-5 advantageBoxBgRight1' />
                    <div className='advantagesBox px-5 py-5 advantageBoxBgRight2' />

                    <div className='advantagesBox px-3 py-1 px-md-5 py-md-5 advantageBoxRight'>
                      <div>
                        <div className='d-flex align-items-center'>
                          <img src={require('../assets/img/home/advantageIcon4.png').default} className="img-fluid imageBoxIconSize mr-3" />
                          <span className="font-CN-Bold imageBoxTitle text-white">{translate(props.lang, "Unrestricted Strategies for Trading Success")}</span> <br />
                        </div>

                        <div className='text-left'>
                          <div className='pt-3'>
                            <span className="font-CN-Thin imageBoxDesc text-white">{translate(props.lang, "Use any legal strategy, from day trading to automated trading, to showcase your skills and ingenuity. The competition rewards the trader with the most profit at the end of the year. Check out the contest rules for more info here.")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='BgDecPos3'>
            <img src={require('../assets/img/home/advantageBgDec1.png').default} className="img-fluid" />
          </div>

          <div className='BgDecPos4'>
            <img src={require('../assets/img/home/advantageBgDec2.png').default} className="img-fluid" />
          </div>

        </div>


        <div className='missionBg'>
          <div className="row justify-content-center align-items-start mx-auto pt-5 mt-5 px-0" style={{ maxWidth: "1600px" }}>
            <div className='col-11 col-lg-3 text-center text-lg-left mb-5'>
              <div className='mt-3'>
                <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "THE ")}</span>
                <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "MISSION ")}</span>
                <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "OF ULTIMATE PINNACLE TRADING BATTLE ROYALE")}</span>

                <div className='position-relative' style={{ minHeight: "100px" }}>
                  <div className='enrollBtnBg mx-auto mx-lg-0'>
                    <a href='/about-us' className='footerTextHref'>
                      <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                        <span className='font-CN-Bold'>ABOUT US</span>
                        <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                      </div>
                    </a>
                  </div>
                  <div className='buttonGradientCenterAnim mx-auto mx-lg-0 d-block d-lg-none' />
                  <div className='buttonGradientLeftAnim mx-auto mx-lg-0 d-none d-lg-block' />
                </div>
              </div>
            </div>

            {/* <img src={require('../assets/img/home/prizeLine.png').default} className="img-fluid pr-5 pl-4 d-none d-lg-block" /> */}
            <div class="copy-box two d-none d-lg-block">
              <div class="inner">
                <div class="line right">
                  <div class="scanner"></div>
                </div>
              </div>
            </div>

            <div className='col-11 col-md-6 col-lg-4 text-center text-lg-left mb-4'>
              <div className='pl-0 pl-md-4 pl-lg-0'>
                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "At ULTIMATE PINNACLE TRADING BATTLE ROYALE, our mission is to provide a platform for traders of all levels to showcase their skills and compete against each other in a fair and transparent environment. Our goal is to foster a community of successful traders with proven performance and provide them with an opportunity to gain recognition and exposure.")}</span>
                <br /><br />
                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "We believe in creating a new era of trading competitions where objective criteria and real-time results are openly displayed, giving every participant a chance to see where they stand in the leaderboard throughout the tournament. Our championship is designed to be a world-class event, where traders can prove their mettle and establish themselves as trading icons in the online FX world.")}</span>
              </div>
            </div>

            <div className='col-11 col-md-6 col-lg-4 text-center text-lg-left mb-4'>
              <div className='pr-0 pr-md-4 pr-lg-0'>
                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "You can read more about the ULTIMATE PINNACLE TRADING BATTLE ROYALE philosophy here.")}</span>
                <br /><br />
                <span className="font-CN-Regular sectionDescText text-white">{translate(props.lang, "Join us now and take your chance to become a part of this dynamic community and gain recognition for your trading skills. Enroll in the ULTIMATE PINNACLE TRADING BATTLE ROYALE today.")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 robotHandBg">
          <div className='col-12 col-md-9 col-lg-5 text-center mb-5'>
            <div className='position-relative'>
              <img src={require('../assets/img/home/robotHand1.png').default} className="img-fluid robotHandImg" />
              <div className='robotHandLightPos'>
                <img src={require('../assets/img/home/robotHandLight.png').default} className="img-fluid lightFade3" />
              </div>
            </div>
          </div>
          <div className='col-11 col-md-11 col-lg-5'>
            <div className='text-center text-lg-left'>
              <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Trade and compete with the best. Join The ")}</span>
              <span className="font-CN-Bold sectionTitleText text-gold">{translate(props.lang, "ULTIMATE PINNACLE TRADING BATTLE ROYALE ")}</span>
              <span className="font-CN-Bold sectionTitleText text-white">{translate(props.lang, "Now.")}</span>

              <div className='position-relative'>
                <div className='enrollBtnBg mx-auto mx-lg-0'>
                  <a href='/about-us' className='footerTextHref'>
                    <div className='enrollBtn d-flex align-items-center justify-content-center mx-auto px-5 py-3 mt-4 '>
                      <span className='font-CN-Bold'>ABOUT US</span>
                      <FaAngleRight className='ml-2' fontSize={20} color='#3AA3E2' />
                    </div>
                  </a>
                </div>
                <div className='buttonGradientCenterAnim mx-auto mx-lg-0 d-block d-lg-none' />
                <div className='buttonGradientLeftAnim mx-auto mx-lg-0 d-none d-lg-block' />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mx-auto pt-5 mt-5 choiceBg">
          <div className='col-11 col-md-9 col-lg-6'>
            <div className='text-center'>
              <span className="font-CN-Thin headerSubTitle text-white">{translate(props.lang, "“You got one choice at the beginning but if you didn't choose right, things got narrow real quick.” ")}</span>
            </div>
            <div className='text-center pt-4'>
              <span className="font-CN-Thin sectionDescText text-white">{translate(props.lang, "Ron Rash")}</span>
            </div>
          </div>
        </div>

      </div>


      <DarkFooter />
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(Index);
